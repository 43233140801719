import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import RtypeOne from "./RtypeOne";
import RtypeTwo from "./RtypeTwo";
import RtypeThree from "./RtypeThree";
import RtypeFour from "./RtypeFour";
import RtypeFive from "./RtypeFive";
import RtypeSix from "./RtypeSix";
import RtypeSeven from "./RtypeSeven";
import RtypeEight from "./RtypeEight";
import RtypeNine from "./RtypeNine";
import RtypeTen from "./RtypeTen";
import RtypeParaOption from "./RtypeParaOption";
import Rtypepara from "./Rtypepara";
import ReviewAnswerAll from "./ReviewAnswerAll";
import ViewExplaination from "./ViewExplaination";
import RaiseQuery from "./RaiseQuery";
import ViewQuery from "./ViewQuery";
import TimeTakenSection from "./TimeTakenSection";
import { ImageUrlPrefix } from "../../../../labels";
import toast from "react-hot-toast";
import Loader from "../../Loader";
// import "../../../../pages/AdmissionForm.css";

export default function ReviewSub() {
  const firstColumnRef = useRef(null);
  const vvvvRef = useRef(null);
  const [flagRaiseQuery, setFlagRaiseQuery] = useState(false);
  // --------Added New ----------
  const [isLoading, setIsLoading] = useState(false);
  // ---------View Explaination Component Popup (Start)------
  const [showViewExplanation, setShowViewExplanation] = useState(false);
  const [viewExplanationDetails, setViewExplanationDetails] = useState({
    element: {},
    userid: null,
  });
  const handleViewExplanationDetails = (element) => {
    setViewExplanationDetails({
      element,
      userid,
    });
  };

  const handleViewExplanationClick = () => {
    setShowViewExplanation(!showViewExplanation);
  };
  // ---------Raise Query Component Popup (Start)------
  const [showRaiseQuery, setShowRaiseQuery] = useState(false);

  const [raiseQueryDetails, setRaiseQueryDetails] = useState({
    element: {},
    userid: null,
  });
  const handleRaiseQueryDetails = (element) => {
    setRaiseQueryDetails({
      element,
      userid,
    });
  };
  const handleRaiseQueryClick = () => {
    setShowRaiseQuery(!showRaiseQuery);
  };
  // ---------View Query Component Popup (Start)------
  const [showViewQuery, setShowViewQuery] = useState(false);
  const [viewQueryDetails, setViewQueryDetails] = useState({
    element: {},
    userid: null,
  });
  const handleViewQueryDetails = (element) => {
    setViewQueryDetails({
      element,
      userid,
    });
  };
  const handleViewQueryClick = () => {
    setShowViewQuery(!showViewQuery);
  };
  const handleRetake = () => {
    const formData = {
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
      user_id: userid,
    };
    AxiosInstance.post("api/test/start", formData)
      .then((res) => {
        if (res.data.status === true) {
          let startTime =
            res.data.data[0].start_time != null
              ? res.data.data[0].start_time
              : "";
          let endTime =
            res.data.data[0].end_time != null ? res.data.data[0].end_time : "";
          let batch_id = res.data.data[0].batch_id;
          let event_id = res.data.data[0].event_id;
          let no_ques = res.data.data[0].no_of_questions;
          let tol_mark = res.data.data[0].tot_marks;
          let attempt_no = res.data.data[0].attempt;
          let method_of_test = res.data.data[0].method_of_test;
          let event_name = res.data.data[0].event_name;
          let type_type_name = res.data.data[0].name;
          let start_date_test = res.data.data[0].start_date;
          let description = res.data.data[0].description;
          const navState = res.data.data[0];
          navigate(res.data.data[0].teams_url, {
            state: {
              eventName: event_name,
              methodOfTest: method_of_test,
              testType: type_type_name,
              date: start_date_test,
              startTime: startTime,
              endTime: endTime,
              totalNoQues: no_ques,
              totalMark: tol_mark,
              descForStudent: description,
              batchID: batch_id,
              eventID: event_id,
              attempt_no: attempt_no,
            },
          });
        } else {
          navigate("/");
          toast.error("You already reach 3 attempt");
        }
      })
      .catch((err) => console.error(err));
  };
  const handleDownloadAnswerSheet = () => {
    const formData = {
      user_id: userid,

      batch_id,

      event_id,
    };
    AxiosInstance.post("api/test/answer/download", formData)
      .then((res) => {
        if (res.data.status) {
          const { path } = res.data.data;
          if (path) window.open(`${ImageUrlPrefix}/${path}`);
        } else {
          throw new Error("download answer sheet pdf error");
        }
      })
      .catch((err) => console.error(err));
  };
  // ---------------Total new End--------

  const [firstColumnHeight, setFirstColumnHeight] = useState(0);
  const [step, setStep] = useState(1);
  const [totalStep, setTotalStep] = useState();
  let step_size = 10;
  const handleNext = () => {
    if (step < totalStep) {
      setStep(step + 1);
    } else {
      setStep(1); // Reset to the first step when reaching the end
    }
  };

  // update same question set height to answer column height

  useEffect(() => {
    const updateColumnHeight = () => {
      if (firstColumnRef.current && vvvvRef.current) {
        const newHeight = firstColumnRef.current.clientHeight;
        setFirstColumnHeight(newHeight);
        vvvvRef.current.style.height = `${newHeight}px`;
      }
    };

    // Initial height update
    updateColumnHeight();

    // Listen for changes in the first column content
    // You need to adapt this to your actual content update logic
    const observer = new MutationObserver(updateColumnHeight);
    observer.observe(firstColumnRef.current, {
      childList: true,
      subtree: true,
    });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      setStep(totalStep); // Go back to the last step when at the first step
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const { batch_id, event_id } = useParams();
  const [formData, setFormData] = useState([
    {
      pk: "",
      user_answer: "",
      lang: "",
    },
  ]);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [step]);
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const [listData, setListData] = useState([]);

  const [ans_sheet_download_req, setAns_sheet_download_req] = useState(null);

  const [quesSet, setQuesSet] = useState([]);

  useEffect(() => {
    handleByUser();
  }, []);

  useEffect(() => {
    if (flagRaiseQuery) {
      handleByUser();
    }
  }, [flagRaiseQuery]);

  const handleByUser = () => {
    setIsLoading(true);
    AxiosInstance.post("api/test/lang/byUser", {
      user_id: userid,
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
    })
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          const lang = res.data.data.test_lang;
          ReviewSubInfo(userid, batch_id, event_id);
        } else {
          setIsLoading(false);
          ReviewSubInfo(userid, batch_id, event_id);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const ReviewSubInfo = (useID, BatId, eVENiD) => {
    setIsLoading(true);
    AxiosInstance.post("api/test/review/answer", {
      user_id: useID,
      batch_id: parseInt(BatId),
      event_id: parseInt(eVENiD),
      // language: lang,
    })
      .then((res) => {
        if (res.data.status === true) {
          let ut = res.data.data.sort((a, b) => a.ques_no - b.ques_no);
          setListData(ut);
          let data_length = res.data.data.length;
          const number_of_steps = Math.ceil(data_length / step_size);
          // console.log(number_of_steps);
          setTotalStep(number_of_steps);
          setAns_sheet_download_req(res.data.ans_sheet_download_req);
          const updatedData = ut.map((item) => ({
            pk: item.pk,
            visited: item.visited,
            is_correct:
              item.answer_status === 0 || item.answer_status === null
                ? false
                : true,
          }));
          setQuesSet(updatedData);
          setFlagRaiseQuery(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };
  //question set click means need to update stepper

  const handleStepClick = (stepnum) => {
    setStep(stepnum);
  };

  // console.log(listData,"listdata" , listData.length,totalStep);

  return (
    <>
      <div
        className="loaderApi"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          zIndex: 999,
        }}
      >
        {isLoading && <Loader />}
      </div>

      <div className=" BathDet">
        <div className="row ">
          <div className="col-md-12">
            <section className="StepOne">
              <div class="heading  dflexbetww ">
                <div class="mt-2 dbetw">
                  <h6 class="Title ">Review Submission</h6>
                  <Link
                    class="CancelButtons  MobileShow "
                    onClick={() => navigateToPreviousScreen()}
                    to={"javascript:void(0)"}
                  >
                    Back to View Test
                  </Link>
                </div>
                <div class="d-flex smButton mobiledown  mt-4">
                  <Link
                    className="CancelButtons MobileHide "
                    onClick={() => navigateToPreviousScreen()}
                    to={"javascript:void(0)"}
                  >
                    Back to View Test
                  </Link>
                  <button
                    type="button"
                    className="DashboardButton me-3"
                    onClick={handleRetake}
                  >
                    <a href="javascript:void(0)">Retake Test</a>
                  </button>
                  {/* <button
                  disabled={ans_sheet_download_req === 1 ? false : true}
                  type="button"
                  className={`DashboardButton ${
                    ans_sheet_download_req === 1 ? " " : "DisabledListvi"
                  }`}
                  onClick={handleDownloadAnswerSheet}
                >
                  <a href="javascript:void(0)">Download My Answer Sheet</a>
                </button> */}
                </div>
              </div>
            </section>
          </div>
          {/* ----New Added Section (Start)---- */}
          <section class="StepOne mt-2">
            <div class="row box">
              <div class="col-md-12">
                <div class="row mt-3">
                  <div className="col-lg-4 col-md-6">
                    <div className="inline-container">
                      <span className="questionSet_wrong mark_number inline-span">
                        1
                      </span>
                      <span className="review-value mb-3 inline-span">
                        Correct Answer
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="inline-container">
                      <span className="questionSet_Review mark_number inline-span">
                        2
                      </span>
                      <span className="review-value mb-3 inline-span">
                        Incorrect Answer
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="inline-container">
                      <span className="questionSet_bg_white mark_numbers inline-span">
                        3
                      </span>
                      <span className="review-value mb-3 inline-span">
                        Question Not Visited
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ----New Section (End)---- */}
          <div className="col-md-12">
            <div className="row  ">
              <div className="col-md-4 col-sm-12 col-xs-12  ">
                <section className="StepOne" ref={firstColumnRef}>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Answer Status</p>
                      <div className="container">
                        <div className="row mt-4 mobileSets">
                          {quesSet.length > 0 &&
                            quesSet.map((el, index) => {
                              const effectiveStep = Math.ceil((index + 1) / 10);
                              if (el.is_correct === true) {
                                return (
                                  <div
                                    onClick={() =>
                                      handleStepClick(effectiveStep)
                                    }
                                    className={`col-md-2 questionSet_wrong `}
                                  >
                                    <span className="mark_number">
                                      {index + 1}
                                    </span>
                                  </div>
                                );
                              } else if (el.visited === 0) {
                                return (
                                  <div
                                    onClick={() =>
                                      handleStepClick(effectiveStep)
                                    }
                                    className={`col-md-2 questionSet_bg_white `}
                                  >
                                    <span className="mark_numbers">
                                      {index + 1}
                                    </span>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    onClick={() =>
                                      handleStepClick(effectiveStep)
                                    }
                                    className={`col-md-2 questionSet_Review `}
                                  >
                                    {" "}
                                    <span className="mark_number">
                                      {index + 1}
                                    </span>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div
                className="col-md-8 col-sm-12 col-xs-12 rightside_scroll"
                ref={vvvvRef}
              >
                <section className="StepOne">
                  {listData.length > 0 &&
                    listData.map((el, index) => {
                      // Calculate the effective step based on the loop count
                      if (el.display_type === 0) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review  mb-0">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <RtypeOne
                                    type={"0"}
                                    index={index}
                                    list={el}
                                  />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}

                                  {/* raise query poop here */}
                                </div>

                                {/* --------end------- */}
                              </div>
                              {/* box_review_set */}
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}
                          </>
                        );
                      } else if (el.display_type === 1) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeTwo
                                    type={"0"}
                                    index={index}
                                    list={el}
                                  />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}
                          </>
                        );
                      } else if (el.display_type === 2) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>
                                <div className="row">
                                  <RtypeThree
                                    type={0}
                                    index={index}
                                    list={el}
                                  />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 3) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeFour type={0} index={index} list={el} />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 4) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeFive
                                    type={"0"}
                                    index={index}
                                    list={el}
                                  />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0  ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 5) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review  mb-0">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeSix type={0} index={index} list={el} />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 6) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeSeven
                                    type={0}
                                    index={index}
                                    list={el}
                                  />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 7) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeEight
                                    type={0}
                                    index={index}
                                    list={el}
                                  />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 8) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeNine type={0} index={index} list={el} />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 9) {
                        return (
                          <>
                            <div className="row ">
                              <div className="col-md-9 box_review mb-0 ">
                                <p className="StepTitle">
                                  {(index + 1).toString().padStart(2, "0")}
                                </p>

                                <div className="row">
                                  <RtypeTen type={0} index={index} list={el} />
                                </div>
                                {/* ---------New Row Added---- */}
                                <div className="inline-containers row">
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewExplanationClick}
                                    onClick={() => {
                                      handleViewExplanationClick();
                                      handleViewExplanationDetails(el);
                                    }}
                                  >
                                    View Explanation
                                  </span>
                                  {el.view_queries.stud_query === null ? (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleRaiseQueryClick}old
                                      onClick={() => {
                                        handleRaiseQueryClick();
                                        handleRaiseQueryDetails(el);
                                      }}
                                    >
                                      Raise Query
                                    </span>
                                  ) : (
                                    <span
                                      className="view-button col-md-4"
                                      // onClick={handleViewQueryClick}
                                      onClick={() => {
                                        handleViewQueryClick();
                                        handleViewQueryDetails(el);
                                      }}
                                    >
                                      View Query
                                    </span>
                                  )}
                                </div>
                                {/* --------end------- */}
                              </div>
                              <div className="col-md-3 box_review mb-0 ">
                                {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                                <ReviewAnswerAll
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              <div className="col-md-12 box_review mt-0 ">
                                <TimeTakenSection Timeel={el} />
                              </div>
                            </div>
                            {/* -------New Row Added (Later -TimeSlat) ----- */}

                            {/*-------- End------ */}
                          </>
                        );
                      } else if (el.display_type === 10) {
                        return (
                          <div className="row ">
                            <div className="col-md-9 box_review mb-0 ">
                              <p className="StepTitle">
                                {(index + 1).toString().padStart(2, "0")}
                              </p>
                              {el.para_text_en !== "" &&
                                (el.lang_type === 1 || el.lang_type === 2) && (
                                  <div className="row">
                                    <Rtypepara
                                      type={"2"}
                                      classET={"question_labels_english"}
                                      index={index}
                                      list={el.para_text_en}
                                    />
                                  </div>
                                )}
                              {el.para_text_tm !== "" &&
                                (el.lang_type === 0 || el.lang_type === 2) && (
                                  <div className="row">
                                    <Rtypepara
                                      type={"2"}
                                      classET={"question_labels_tamil"}
                                      index={index}
                                      list={el.para_text_tm}
                                    />
                                  </div>
                                )}

                              <div className="row">
                                <RtypeParaOption
                                  type={0}
                                  index={index}
                                  list={el}
                                />
                              </div>
                              {/* ---------New Row Added---- */}
                              <div className="inline-containers row">
                                <span
                                  className="view-button col-md-4"
                                  // onClick={handleViewExplanationClick}
                                  onClick={() => {
                                    handleViewExplanationClick();
                                    handleViewExplanationDetails(el);
                                  }}
                                >
                                  View Explanation
                                </span>
                                {el.view_queries.stud_query === null ? (
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleRaiseQueryClick}old
                                    onClick={() => {
                                      handleRaiseQueryClick();
                                      handleRaiseQueryDetails(el);
                                    }}
                                  >
                                    Raise Query
                                  </span>
                                ) : (
                                  <span
                                    className="view-button col-md-4"
                                    // onClick={handleViewQueryClick}
                                    onClick={() => {
                                      handleViewQueryClick();
                                      handleViewQueryDetails(el);
                                    }}
                                  >
                                    View Query
                                  </span>
                                )}
                              </div>
                              {/* --------end------- */}
                            </div>
                            <div className="col-md-3 box_review mb-0 ">
                              {/* <RtypeOne type={0} index={index} list={el} li_bbb ={1} /> */}
                              <ReviewAnswerAll
                                type={0}
                                index={index}
                                list={el}
                              />
                            </div>
                            <div className="col-md-12 box_review mt-0 ">
                              <TimeTakenSection Timeel={el} />
                            </div>
                          </div>
                        );
                      }
                    })}
                </section>
              </div>
            </div>
          </div>
        </div>
        {showViewExplanation && (
          <ViewExplaination
            element={viewExplanationDetails.element}
            userid={viewExplanationDetails.userid}
            showModal={showViewExplanation}
            onSetShowModal={setShowViewExplanation}
          />
        )}
        {showRaiseQuery && (
          <RaiseQuery
            onsetflagQuery={setFlagRaiseQuery}
            element={raiseQueryDetails.element}
            userid={raiseQueryDetails.userid}
            showModal={showRaiseQuery}
            onSetShowModal={setShowRaiseQuery}
          />
        )}
        {showViewQuery && (
          <ViewQuery
            element={viewQueryDetails.element}
            userid={viewQueryDetails.userid}
            showModal={showViewQuery}
            onSetShowModal={setShowViewQuery}
          />
        )}
      </div>
    </>
  );
}
