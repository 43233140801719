import React, { useEffect, useState } from "react";
import "./Popup.css";
import { Navigate, useNavigate } from "react-router-dom";

const Popup = ({ onContinue, message, isAlert, performApiBinding }) => {
  // console.log("---- its calling ");
  const navigate = useNavigate();
  // --------------start----
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Initialize count from sessionStorage
    const storedCount = sessionStorage.getItem("buttonClickCount");
    // if (storedCount) {
    //   setCount(parseInt(storedCount, 10));
    //   if (storedCount >= 1) {
    //     sessionStorage.setItem("buttonClickCount", 0);
    //     console.log("xcvcxvxcvcxv ----------------");
    //     navigateToPreviousScreen();
    //   }

    // }
    if (storedCount) {
      const count = parseInt(storedCount, 10);
      if (count >= 1) {
        // navigateToTwo();
        // console.log(count, "countcountcountcount");
        setCount(count);
        sessionStorage.setItem("buttonClickCount", 0);
        localStorage.setItem("buttonClickCount", 0);
      } else {
        // console.log("lalitha-----", count);
        setCount(count);
      }
    }

    // Reset count when the tab is closed
    const handleTabClose = () => {
      sessionStorage.setItem("buttonClickCount", 0);
      localStorage.setItem("buttonClickCount", 0);
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  // ---------------------
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const navigateToPreviousScreen = () => {
    performApiBinding();
    // navigate(-3); // Navigate back by one step in the history
  };
  const navigateToTwo = () => {
    navigate(-2); // Navigate back by one step in the history
  };

  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="popup-header">
            <h2 className="full-title">
              Attention: Full Screen Mode Online Exam Instructions
            </h2>
          </div>
          <div className="popup-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {count >= 1 ? (
                    <>
                      <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div
                            className="Warning_Popup h-0"
                            style={{ textAlign: "center" }}
                          >
                            <strong>WARNING: </strong> <br /> You have pressed
                            the ESC or F11 button on your keyboard. As a result,
                            the exam will be terminated, <br /> and you will be
                            marked as absent.
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div className="Note_Popup h-0">
                            <strong>NOTE: </strong> Keep your browser in{" "}
                            <b>full-screen mode</b> throughout the entire exam.
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Keep your browser in{" "}
                          <b>full-screen mode</b> throughout the entire exam.
                        </div>
                      </div>
                    </div>
                  )}

                  <p className="fullscreen-paragraph">
                    <ul>
                      <li>Do not reload the page.</li>
                      <li>Do not close the browser.</li>
                      <li>Do not minimize the browser.</li>
                      <li>Do not switch tabs.</li>
                      <li>Do not switch to other applications.</li>
                      <li>Do not click the back button.</li>
                      <li>Do not press ESC, F1 (help), and F11.</li>
                      <li>Set your screen's sleep time to "Never".</li>
                    </ul>
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Reloading the page, closing
                          the browser, switching tabs, switching to other
                          applications, pressing Shift or F1 button or Clicking
                          the button will result in your exam being
                          automatically submitted and terminated.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Pressing ESC or F11 will also
                          result in your exam being terminated, and you will be
                          marked as absent.
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <button
              onClick={count >= 1 ? navigateToPreviousScreen : onContinue}
              // disabled={count >= 1 }
              className="DashboardButton"
            >
              {count >= 1 ? "Ok" : "I Understand and Accept"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
